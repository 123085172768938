var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CCard',[_c('CCardHeader',{staticClass:"d-flex align-items-center"},[_c('TMessage',{attrs:{"content":"Box","bold":""},scopedSlots:_vm._u([{key:"suffix",fn:function(){return [_vm._v(" "+_vm._s(_vm.box.id)+" ")]},proxy:true}])}),_c('SMessageBoxStatus',{staticClass:"ml-1 my-auto",attrs:{"box":_vm.box}}),(_vm.editable)?_c('TButton',{staticClass:"ml-auto",attrs:{"content":"Add tags","variant":"outline","icon":"cil-plus"},on:{"click":function($event){_vm.showModalAddTags = true}}}):_vm._e(),_c('TButtonDelete',{staticClass:"ml-1",attrs:{"size":"sm","variant":""},on:{"click":_vm.deleteSKU}}),_c('SModalAddTagSku',{attrs:{"show":_vm.showModalAddTags},on:{"update:show":function($event){_vm.showModalAddTags=$event}}})],1),_c('CCardBody',[(_vm.box.tags && _vm.box.tags.length)?_c('div',{staticClass:"d-flex flex-wrap mb-3"},[_c('TMessage',{staticClass:"mr-2 h5",attrs:{"content":"Tags","bold":""},scopedSlots:_vm._u([{key:"suffix",fn:function(){return [_vm._v(":")]},proxy:true}],null,false,3502003184)}),_vm._l((_vm.box.tags),function(tag){return _c('div',{key:tag.id,staticClass:"mr-1"},[_c('SMessageBoxTag',{attrs:{"tag":tag,"removable":""}})],1)})],2):_vm._e(),_c('CRow',[_c('CCol',{staticClass:"mb-3",attrs:{"col":"12","md":"6"}},[_c('TTableAsForm',{staticStyle:{"font-size":"16px"},attrs:{"fields":_vm.boxFields,"data":_vm.box,"splitLeft":7,"addRowClasses":['pb-2']},scopedSlots:_vm._u([{key:"updated_at",fn:function(ref){
var value = ref.value;
return [_c('TMessageDateTime',{attrs:{"dateOnly":"","content":value}})]}},{key:"closed_at",fn:function(ref){
var value = ref.value;
return [_c('TMessageDateTime',{attrs:{"dateOnly":"","content":value}})]}},{key:"number_days_storage",fn:function(ref){
var value = ref.value;
return [_c('TMessageNumber',{attrs:{"value":value}})]}},{key:"length",fn:function(ref){
var value = ref.value;
return [_c('TMessageNumber',{attrs:{"value":value,"editable":_vm.editable,"suffix":"cm"},on:{"change":function($event){return _vm.updateBox('length', $event)}}})]}},{key:"width",fn:function(ref){
var value = ref.value;
return [_c('TMessageNumber',{attrs:{"value":value,"editable":_vm.editable,"suffix":"cm"},on:{"change":function($event){return _vm.updateBox('width', $event)}}})]}},{key:"height",fn:function(ref){
var value = ref.value;
return [_c('TMessageNumber',{attrs:{"value":value,"editable":_vm.editable,"suffix":"cm"},on:{"change":function($event){return _vm.updateBox('height', $event)}}})]}},{key:"volume",fn:function(ref){
var value = ref.value;
return [_c('TMessageVolume',{attrs:{"value":value}})]}},{key:"weight",fn:function(ref){
var value = ref.value;
return [_c('TMessageNumber',{attrs:{"value":value,"editable":_vm.editable,"suffix":"kg"},on:{"change":function($event){return _vm.updateBox('weight', $event)}}})]}},{key:"duplicate",fn:function(ref){
var value = ref.value;
return [_c('TMessageNumber',{attrs:{"value":value,"editable":!_vm.box.is_parent_box && _vm.editable},on:{"change":function($event){return _vm.updateBox('duplicate', $event)}}})]}},{key:"sfa_id",fn:function(ref){
var value = ref.value;
return [(value)?_c('TMessage',{attrs:{"content":value,"bold":"","noTranslate":""}}):_vm._e()]}},{key:"tracking",fn:function(){return [(_vm.box.sfa)?_c('TMessage',{attrs:{"content":_vm.box.sfa.tracking_code,"bold":"","noTranslate":""}}):_vm._e()]},proxy:true}])})],1),_c('CCol',{staticClass:"mb-3",attrs:{"col":"12","md":"6"}},[_c('TTableAsForm',{staticStyle:{"font-size":"16px"},attrs:{"fields":_vm.quantityFields,"splitLeft":7,"data":_vm.box,"addRowClasses":['pb-2']}})],1),_c('CCol',{staticClass:"mb-3",attrs:{"col":"12","md":"6"}},[(_vm.refFields.length)?_c('TTableAsForm',{attrs:{"title":"Reference","fields":_vm.refFields,"splitLeft":7,"data":_vm.box},scopedSlots:_vm._u([{key:"box_parent_id",fn:function(ref){
var value = ref.value;
return [(value)?_c('TLink',{attrs:{"content":value,"bold":"","to":_vm.lodash.getReferenceLink('box', value)}}):_vm._e()]}},{key:"box_ref_id",fn:function(ref){
var value = ref.value;
return [(value)?_c('TLink',{attrs:{"content":value,"bold":"","to":_vm.lodash.getReferenceLink('box', value)}}):_vm._e()]}}],null,false,2906394116)}):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }